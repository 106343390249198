/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://nede0a62bd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "memesrcContentApi",
            "endpoint": "https://w6gvz3l0tb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "publicapi",
            "endpoint": "https://j4c1kucvqk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://faqco2xj5vc27o7qyuw6uhcyxq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wcmcdnqpjvbbdjizwybdyrvhj4",
    "aws_cognito_identity_pool_id": "us-east-1:84dc731c-f9f4-4aea-8379-bf4dfae635bd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fU9hjTCDb",
    "aws_user_pools_web_client_id": "1nqqe3v3ei8rsk1sthlh9josp9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "meme-20221129192452-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://meme-20221129192452-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "memesrc-generated-images191629-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
